@tailwind base;
@tailwind components;
@tailwind utilities;

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-around {
  justify-content: space-around;
}

.w-100 {
  width: 100%;
}

.icon-circle {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 30px;
  text-align: center;
}

.card-clickable {
  cursor: pointer;
}

.col-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.figure-card {
  min-height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__toast {
  border-radius: 4px;
}

.avatar {
  background-size: contain !important;
}

.sender {
  font-weight: 600;
  font-size: 11px !important;
}

.message .text {
  font-size: 13px;
  font-weight: 500;
  word-break: break-word !important;
}

.flex-between {
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-content: center;
  display: flex !important;
}
